import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './assets/scss/style.scss';

// import Create from './components/Redirector/Redirector';
import Loader from './components/Loader/Loader';

const Home = lazy(() => import('./components/Home/Home'));
const SecretEditor = lazy(() => import('./components/SecretEditor/SecretEditor'));
const Redirector = lazy(() => import('./components/Redirector/Redirector'));

function App() {
  return (
    <Router>
      <div className="">
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}


        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

{/* <Spinner /> */}
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/hasodi/:secretKey" component={SecretEditor}></Route>
            {/* <Route path="/create" component={Create}></Route>
            <Route path="/v/:id" component={Card}></Route>
            <Route path="/edit/:id/:stage/:step" component={EditCard}></Route>
            <Route path="/edit/:id/:stage/" component={EditCard}></Route>
            <Route path="/edit/:id/" component={EditCard}></Route> */}
            <Route path="" component={Redirector}></Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

function Spinner() {
  return <Loader />
}

export default App;
