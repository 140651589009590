import React, { Component } from 'react'

import './loader.scss';

import Logo from '../Logo/Logo';

export default class Loader extends Component {

    loading_text = [
        'מכין דייסה...',
        'מכין שוקו...',
        'מכינים לך תה...',
        'לספור עד שלוש...',
        'לספור שלוש כבשים...',
        'מכין לך צ\'אי...',
        'מביא לך כרית...',
        'זורק את הזבל ובא...',
        'שותה מים ובא...',
        'מכין לך פיצה...',
        'מכין לך עוגה...',
        'מביא לך שוקולד...',
        'מביא לך שוקולד...',
    ]
    
    get_random(list) {
        return list[Math.floor((Math.random() * list.length))];
    }

    render() {
        return (
            <div className={"fullheight middle grow " + (!this.props.disableMinHeight ? 'min-height-100vh' : '')}>
                <header>
                    <Logo></Logo>
                </header>
                <div className="content">
                    <div className="loader-page text-center">
                        {/* <div className="loader"></div>
                        <h2>{this.props.title || 'טוען...'}</h2> */}

                        <div className="loading-wrap">
                            <div className="loading-inside">
                                <div className="loading-dots">
                                    <span className="dot dot-1"></span>
                                    <span className="dot dot-2"></span>
                                    <span className="dot dot-3"></span>
                                </div>
                                <div className="loading-text">
                                    {this.props.title || this.get_random(this.loading_text)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="footer-links">
                    </div>
                </footer>
            </div>
        )
    }
}
